import * as React from "react"
import Layout from "../../components/layout"
import Servicelinks from "../../components/servicelinks"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
import useIntersection from '../../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '-90px', // Adjust this to place the top of the section at the center
    threshold: 0.2 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};

const WPDev = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'service-page'
    }}
/>
  <Layout pageTitle="WPDev">
    <section id="main">
      <div id="service-intro">
        <div className="left-col">
<h1><span className="revealblack">WordPress Development</span></h1>
<p><span className="revealblack">We are Wordpress development from Melbourne that brings open source with anything is possible, flexible, functional, scalable and ready to perform for your website or web appplication.</span></p>

<a className="button sendusemail" href="#sendusemail"><span className="seemore"><i>&#xe801;</i></span>Send us an email</a>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/services/featured-digital-stragey.jpg'} alt="Wordpress Development" />
        </div>
      </div>
    </section>

    <Section id="service-content">
      <div id="inner-content">

<h2>Why Wordpress?</h2>
<p>A Content Management System (CMS) that makes managing your website easy.</p>
<p>WordPress is an open source and one of the most popular in the world. <a href="https://www.google.com/search?q=percenateg+website+use+wordpress&amp;oq=percenateg+website+use+wordpress" target="_blank" rel="noreferrer">43%</a> of websites on the Internet utilise WordPress and it has one of the largest developer community. This means that several plugins have already been developed.</p>
<p><img className="picture" src={'/img/single/wordpress-icon.png'}  alt="Wordpress" /></p>
<p>Clients like WordPress and it is very user-friendly. Customisations are limitless due to Wordpress being open source with our own add-on developed functionalities, content managing interface and using the plugins that are available for suitable projects such as;</p>
<ul>
<li>Elementor Page Builder <a href="https://elementor.com" target="_blank" rel="noreferrer">https://elementor.com </a></li>
<li>Advanced Custom Fields <a href="https://www.advancedcustomfields.com" target="_blank" rel="noreferrer">https://www.advancedcustomfields.com </a></li>
<li>Woocommerce <a href="https://woocommerce.com/" target="_blank" rel="noreferrer">https://woocommerce.com/ </a></li>
<li>Event Calendar <a href="https://theeventscalendar.com" target="_blank" rel="noreferrer">https://theeventscalendar.com </a></li>
<li>Restrict Content Pro <a href="https://restrictcontentpro.com" target="_blank" rel="noreferrer">https://restrictcontentpro.com </a></li>
<li>LearnDash <a href="https://www.learndash.com" target="_blank" rel="noreferrer">https://www.learndash.com </a></li>
</ul>
<h2>Custom plugins and API integrations</h2>
<p>We provide secure and reliable WordPress Plugin development services, <AniLink cover to="/capabilities/api-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>API and 3th party integrations</AniLink>, to work with your website. Expanding the functionality of your website with our custom plugins, custom post types and taxonomies.</p>
<h2>Custom WordPress development</h2>
<p>A simple bespoke WordPress website or an advanced eCommerce solution with <AniLink cover to="/capabilities/ecommerce-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>Woocommerce</AniLink>, we do it all to meet your business goals. We specialise in pushing what WordPress can do — you name it, we can build it.</p>
<p>You need customised WordPress website that work with your marketing strategy, developing a web application project or our prebuilt and purchased WordPress themes that deliver a cost effective solution for small businesses.</p>
<p>Do you have a web development project? Send us brief details via email and we will be in touch.</p>


      </div>
      <Servicelinks></Servicelinks>
    </Section>
</Layout>

  </>
  )
}

export default WPDev


export const Head = () => (
  <>
    <title>Wordpress Development Melbourne - Tobstar</title>
<meta name="description" content="With Wordpress development anything is possible, flexible, functional, scalable and ready to perform for your online business and deliver your visitors with a seamless user experience." />
  </>
)